import React from "react";
import SEO from "../components/seo";
import Chapter6 from "src/views/Chapter6";

const IndexPage = () => {
  return (
    <>
      <SEO title="Śmierć od kuli niemieckiego snajpera" description="4 sierpnia 1944 roku niemiecki snajper zabił Krzysztofa Kamila Baczyńskiego. Tego samego dnia poetę pochowano w powstańczym grobie. Jego żona Basia zginęła niecały miesiąc później." image={require('images/seo/6.jpg')}/>
      <Chapter6 />
    </>
  );
};

export default IndexPage;
