import React from "react"
import AudioPlayer, { AudioPlayerGroup } from "src/components/AudioPlayer"
import VideoPlayer from "src/components/VideoPlayer"
import TitlePage from "src/components/TitlePage"
import CustomGrid, { Section, Group } from "src/components/CustomGrid"
import { GRIDS } from "src/util"
import { useArticles } from "src/hooks"
import parse from "html-react-parser"
import { BodyTextWrap, TitleContrast } from "src/atoms/typography"
import { Bookshelf } from "src/atoms"
import NextChapter from "../../components/NextChapter"
import { AnimatePresence, motion } from "framer-motion"

const Chapter6 = () => {
  const articles = useArticles({ articleCategory: 9051 })
  const { list } = articles
  if (list.length)
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3.5 }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <TitlePage
              title={list[0]?.Title}
              subtitle={list[0].Lead}
              description={parse(list[0].Content)}
            />
            <Section>
              <CustomGrid styles={{ marginBottom: "2rem" }}>
                <div>
                  <TitleContrast>{list[1].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[1].Content)}
                  </BodyTextWrap>
                </div>
                <VideoPlayer src={list[2].CustomFields[0].Value} />
              </CustomGrid>
              <CustomGrid
                photos={list.slice(3, 7)}
                grid={GRIDS.p16}
                heightRatio={0.37}
              />
            </Section>
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[7].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[7].Content)}
                  </BodyTextWrap>
                  <AudioPlayerGroup list={[list[8]]} />
                </div>
                <CustomGrid
                  photos={[list[9], list[10]]}
                  grid={GRIDS.p11}
                  heightRatio={0.67}
                />
              </CustomGrid>
            </Section>
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[11].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[11].Content)}
                  </BodyTextWrap>
                </div>
                <CustomGrid
                  photos={[list[12]]}
                  grid={GRIDS.p0}
                  heightRatio={0.65}
                  photoStyles={[{ backgroundPosition: "center 10%" }]}
                />
              </CustomGrid>
              <Group marginT="4rem">
                <CustomGrid>
                  <AudioPlayerGroup list={list.slice(13, 17)} />
                </CustomGrid>
              </Group>
            </Section>
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[17].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[17].Content)}
                  </BodyTextWrap>
                </div>
                <CustomGrid
                  photos={[list[18]]}
                  grid={GRIDS.p0}
                  heightRatio={0.65}
                />
              </CustomGrid>
              <Group marginT="4rem">
                <CustomGrid grid={GRIDS.p4}>
                  <AudioPlayerGroup list={list.slice(19, 22)} />
                </CustomGrid>
              </Group>
            </Section>
            <Section>
              <Bookshelf />
              <CustomGrid grid={GRIDS.p4}>
                <AudioPlayerGroup list={list.slice(23, 26)} />
              </CustomGrid>
              <Group marginB="4rem">
                <CustomGrid
                  photos={list.slice(26, 32)}
                  grid={GRIDS.p4}
                  heightRatio={0.52}
                />
              </Group>
              <CustomGrid grid={GRIDS.p4}>
                <AudioPlayerGroup list={list.slice(32, 34)} />
              </CustomGrid>
            </Section>
          </div>
          <NextChapter title="Warszawa" href="/warszawa" />
        </motion.div>
      </AnimatePresence>
    )
  return null
}

export default Chapter6
